import React, {useEffect, useState} from "react"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import HTML from "../components/HTML"
import { Link } from "gatsby"
import view from "../components/dealers/dealerStyles.module.scss";
import Directions from "../components/directions/Directions";
import Offers from "../components/currentOffers/Offers"


const Dealers = (props) => {

  const [countState, setCountState] = useState(true);
  const { pageContext } = props
  const dealers = pageContext.dealers;
  const dealerInfo =  pageContext.dealerInfo;
  const dealerRefs = pageContext.dealerRefs;
  const backgroundImage =  pageContext.dealerInfo.bgBackground;
  const dealerName = dealers.dealerName;
  const dealerAddress= dealers.dealerAddress;
  const siteURL= dealers.websiteLink;
  const offersURL= dealers.offersPageURL;
  const partsURL= dealers.partsPageURL;
  const serviceURL= dealers.servicePageURL;
  const inventoryURL= dealers.inventoryURL;
  const phoneNumber= dealers.mainPhoneNumber;
  const dealerImage= dealers.dealerServiceImage;
  const quickGenId = pageContext.dealerInfo.quickgenID
  const liveShotDefault = false;
  const cpoDefault = false;
  const salesHours = dealers.salesHours;
  const serviceHours = dealers.serviceHours;
  
  useEffect(()=>{
    var count = 10;
    var countdown = setInterval(function(){
      document.getElementById('redirect-counter').innerHTML = count;
      document.getElementById('redirect-counter-mobile').innerHTML = count;
      if (count == 0) {
        clearInterval(countdown);
        window.open(siteURL, "_blank");
        setCountState(false);		
      }
      count--;
    }, 1000);
  },[])

  return(
    <Layout>
      <>
        <div style={{position:'relative', width:'100%', overflow:'hidden', maxWidth: '1750px', margin:' 0 auto'}}>
          <img src={backgroundImage} style={{position:'relative',display:'block', width:'100%', zIndex:-1, }} alt="Banner, Get Ready all-new redesigned 2022 CIVC" />
          <div className={view["banner-block-containers"]}>
            <div className={view["banner-block-left"]} style={{ width:'35%', background:'rgba(0,0,0,.5)', padding:'0px 10px'}}>
            
            <h1 style={{color:'#fff', textAlign:'center'}}>{dealerName}</h1>
            {dealerImage?
              <img src={dealerImage} className={view["banner-block-image"]} alt={dealerName} />
              :
              null
            }

            <p style={{color:'#fff',fontFamily:'var(--primary-font)', fontWeight:'bold', textAlign:'center', margin:'20px auto'}}>{dealerAddress}</p>
            {countState? <p style={{color:'#fff',fontFamily:'var(--primary-font)', fontWeight:'bold', textAlign:'center', margin:'20px auto'}}>
              Redirecting to dealer's site in <span id="redirect-counter" style={{backgroundColor:'#333', borderRadius:15,  width:30, height:30, display:'inline-block', lineHeight:'30px'}}></span></p>:null
              }

            <div style={{ alignItems:'center', justifyContent:'space-evenly', display:'flex', marginBottom:20}}>
              <a href={siteURL} style={{display:'block', color:'#fff', background:'var(--primary-accent)', padding:'10px 20px', textAlign:'center', fontWeight:'bold', width: '50%', margin:'0 10px'}}  target="_blank" rel="noreferrer">ENTER SITE</a>
              <a href={`tel:${phoneNumber}`} style={{display:'block', color:'#fff', background:'var(--primary-accent)', padding:'10px 20px', textAlign:'center', fontWeight:'bold', width: '50%', margin:'0 10px'}} >CALL US</a>
            </div>

          </div>
          </div>
        </div>
        <div className={view["mobile-location-container"]}>
      
             
          <h1 style={{color:'#000', textAlign:'center'}}>{dealerName}</h1>
          <img src={dealerImage} className={view["banner-block-image"]} alt={dealerName} />
          
          <p style={{color:'#000',fontFamily:'var(--primary-font)', fontWeight:'bold', textAlign:'center', margin:'20px auto'}}>{dealerAddress}</p>
          {countState? <p style={{color:'#000',fontFamily:'var(--primary-font)', fontWeight:'bold', textAlign:'center', margin:'20px auto'}}>Redirecting to dealer's site in <span id="redirect-counter-mobile" style={{backgroundColor:'#333', borderRadius:15,  width:30, height:30,display:'inline-block', lineHeight:'30px', color:'#fff' }}></span></p>:null}
          <div  className={view["banner-mobile-buttons-row"]}>
            <a href={siteURL} className={view["banner-mobile-buttons"]} target="_blank" rel="noreferrer">ENTER SITE</a>
            <a href={`tel:${phoneNumber}`} className={view["banner-mobile-buttons"]} >CALL US</a>
          </div>

        </div>
        <div  className={view["button-rows"]}>
          {inventoryURL?
            <a href={inventoryURL} className={view["button-row-buttons"]} target="_blank" rel="noreferrer">VIEW INVENTORY</a>
            :
            null
          }
          {offersURL?
            <a href={offersURL} className={view["button-row-buttons"]}  target="_blank" rel="noreferrer">OFFERS</a>
            :
            null
          }
          {partsURL?
            <a href={partsURL} className={view["button-row-buttons"]}  target="_blank" rel="noreferrer">PARTS</a>
            :
            null
          }
          {serviceURL?
            <a href={serviceURL} className={view["button-row-buttons"]}  target="_blank" rel="noreferrer">SERVICE</a> 
            :
            null
          }
          
        </div>
       
        <h2 style={{textAlign:'center', margin: '35px auto'}} >Hours and Directions</h2>
        <div style={{maxWidth:1300, margin:'0 auto', width:'95%'}}>
          <Directions dealerAddress={dealerAddress} dealerName={dealerName} serviceHours={serviceHours} salesHours={salesHours}/>
        </div>
        {quickGenId?
          <>
            <h2 style={{textAlign:'center', margin: '35px auto'}} >CURRENT OFFERS</h2>
            <Offers key={"Offers"} dealers={dealerRefs} quickgenId={quickGenId} liveActionShots={liveShotDefault} data={cpoDefault} dealerInfo={dealerInfo}/>
          </>
        :
        null
        }
      
      
      </>
    </Layout>
  )


}


export default Dealers;